exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-billing-tsx": () => import("./../../../src/pages/billing.tsx" /* webpackChunkName: "component---src-pages-billing-tsx" */),
  "component---src-pages-confirm-tsx": () => import("./../../../src/pages/confirm.tsx" /* webpackChunkName: "component---src-pages-confirm-tsx" */),
  "component---src-pages-dashboard-tsx": () => import("./../../../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-forgot-tsx": () => import("./../../../src/pages/forgot.tsx" /* webpackChunkName: "component---src-pages-forgot-tsx" */),
  "component---src-pages-index-2-tsx": () => import("./../../../src/pages/index2.tsx" /* webpackChunkName: "component---src-pages-index-2-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-me-tsx": () => import("./../../../src/pages/me.tsx" /* webpackChunkName: "component---src-pages-me-tsx" */),
  "component---src-pages-notifications-tsx": () => import("./../../../src/pages/notifications.tsx" /* webpackChunkName: "component---src-pages-notifications-tsx" */),
  "component---src-pages-old-index-tsx": () => import("./../../../src/pages/old-index.tsx" /* webpackChunkName: "component---src-pages-old-index-tsx" */),
  "component---src-pages-project-[uuid]-tsx": () => import("./../../../src/pages/project/[uuid].tsx" /* webpackChunkName: "component---src-pages-project-[uuid]-tsx" */),
  "component---src-pages-project-index-tsx": () => import("./../../../src/pages/project/index.tsx" /* webpackChunkName: "component---src-pages-project-index-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-template-tsx": () => import("./../../../src/pages/template.tsx" /* webpackChunkName: "component---src-pages-template-tsx" */)
}

